"use client";

import { faCircleExclamation } from "@fortawesome/pro-thin-svg-icons";
import { useTranslations } from "next-intl";

import { useUser } from "~/context/user";
import useCrisp from "~/hooks/useCrisp";
import UnauthenticatedLayout from "~/layouts/Unauthenticated";
import { EmptyBarrier } from "~/shared/Barriers";
import Button from "~/shared/Button";
import Card from "~/shared/Card";
import routes from "~/utils/routes";

function Error() {
  const Crisp = useCrisp();
  const t = useTranslations("components.Error");
  return (
    <main className="flex flex-1 flex-col items-center justify-center">
      <Card cardClassName="px-12" hideDivider>
        <EmptyBarrier
          description={
            <div className="flex flex-col space-y-6">
              <span>{t("anErrorOccurred")}</span>
              <div className="space-x-4">
                <Button buttonColor="blue" to={routes.home}>
                  {t("goHome")}
                </Button>
                <button onClick={() => Crisp?.chat.open()}>
                  {t("talkToSupport")} <span aria-hidden="true">&rarr;</span>
                </button>
              </div>
            </div>
          }
          icon={faCircleExclamation}
          title={t("title")}
        />
      </Card>
    </main>
  );
}

export default function ErrorPage() {
  const { user } = useUser();

  return (
    <>
      {user?.id ? (
        <div className="flex min-h-screen flex-col">
          <div className="flex min-h-screen flex-col justify-between pt-16 md:pt-0">
            <Error />
          </div>
        </div>
      ) : (
        <UnauthenticatedLayout>
          <Error />
        </UnauthenticatedLayout>
      )}
    </>
  );
}
